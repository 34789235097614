import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import TextContainer from "../components/text-container"

const NotFoundPage = () => (
  <Layout title="404 | Page Not Found" description="We Couldn't find that page" location="/404">
    <TextContainer>
      <div className="text-center pt-16">
        <h1>404</h1>
        <p>We're sorry, but we couldn't find that page.</p>
        <Link to="/" className="btn secondary">Go back to the homepage</Link>
      </div>
    </TextContainer>
  </Layout>
)

export default NotFoundPage
